<template>

  <b-nav-item
    v-b-modal="'role-'+indexref"
    name="addEditRole"
  >
    {{ (Role.id>0)?'Edit':'Add New' }}
    <section class="roles-permission">
      <b-modal
        :id="'role-'+indexref"
        :ref="'role-'+indexref"
        centered
        hide-footer
        size="lg"
      >
        <b-card
          class="text-center mb-4"
          :title="(Role.id>0)?`Modifica Ruolo ${Role.name[0].toUpperCase()}${Role.name.slice(1)}`:'Add New Role'"
          sub-title="Set role permissions"
        >
          <b-form @submit.prevent="saveRole()">
            <b-row class="match-height text-left mb-4">
              <b-col
                md="12"
                lg="12"
              >
                <h4 class="mt-2 pt-50">
                  Permessi Ruoli
                </h4>
              </b-col>


              <b-col
                md="12"
                lg="12"
              >
                <b-form-group
                  label="Role Name"
                  label-for="fh-name"
                >
                  <b-form-input
                    id="fh-name"
                    v-model="Role.name"
                    placeholder="Ruolo"
                    :disabled="(Role.id>0)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4"><span class="mr-25 align-middle font-weight-bolder">Administrator Access <feather-icon
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="'Allows a full access to the system'"
                icon="InfoIcon"
                size="12"
                @change="selectAll()"
              /></span>
              </b-col>
              <b-col
                md="8"
              >
                <b-form-group>
                  <b-form-checkbox
                    id="select-all"
                    v-model="isAdminitratorRule"
                    name="select-all"
                    @change="selectAll()"
                  >
                    Select All
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                lg="12"
              >
                <b-table
                  striped
                  responsive
                  class="mb-0 font-weight-bolder"
                  :items="Role.abilities"
                >
                <template #head(subject)>
                    <div class="text-nowrap" />
                  </template>
                  <template #cell(subject)="" />
                  <template #cell(name)="data">
                    {{ data.value }}
                  </template>
                  <template #cell()="data">
                    <b-form-checkbox
                      :v-bind="data"
                      :checked="data.value"
                      @change="statusChanged(data)"
                    />
                  </template>
                </b-table>
              </b-col>
              <!-- submit and reset -->
              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  {{ (Role.id>0)? "Modifica" : "Aggiungi" }}
                </b-button>
              </b-col>
            </b-row>

          </b-form>
        </b-card>


      </b-modal>
    </section>
  </b-nav-item>

</template>

<script>

import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BCard,
  BFormInput, BModal, VBModal, BNavItem, BTable, BFormCheckbox, VBTooltip, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

export default {
  components: {

    BModal,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BCard,
    BFormInput,
    BNavItem,
    BTable,
    BFormCheckbox,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    indexref: {
      type: String,
      default: "0",
    },
    // eslint-disable-next-line vue/prop-name-casing
    Role: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const isAdminitratorRule = ref(false)
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in props.Role.abilities[0]) {
      // eslint-disable-next-line no-restricted-syntax
      for (const element of props.Role.abilities) {
        if (key != 'subject') {
          if (Object.hasOwnProperty.call(element, key)) {
            if (!element[key]) {
              isAdminitratorRule.value = false
              break
            } else {
              isAdminitratorRule.value = true
            }
          }
        }
      }
    }
    return {
      isAdminitratorRule,
    }
  },
  methods: {
    statusChanged(data) {
      this.Role.abilities.filter(x => x.subject == data.item.subject)[0][data.field.key] = !this.Role.abilities.filter(x => x.subject == data.item.subject)[0][data.field.key]
      // this.$emit('onChangeCurrentRole', { index: data.index, value: data.rowSelected, we: this.currentRole })
    },
    selectAll() {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in this.Role.abilities[0]) {
        // eslint-disable-next-line no-restricted-syntax
        for (const element of this.Role.abilities) {
          element.read = this.isAdminitratorRule
          element.write = this.isAdminitratorRule
          element.create = this.isAdminitratorRule
          element.delete = this.isAdminitratorRule
          // if (key != 'subject' || key != 'name') {
          //   if (Object.hasOwnProperty.call(element, key)) {
          //     const value = element[key]
          //     element[key] = this.isAdminitratorRule
          //   }
          // }
        }
      }
    },
    saveRole() {
      this.$emit('saveRole', this.Role)
      this.$bvModal.hide(`role-${this.indexref}`)
    },
  },
}
</script>

