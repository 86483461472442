<template>
  <b-row v-if="$can('read', 'Permessi')">
    <b-col
      v-for="(role, index) of roles"
      :key="role.name"
      cols="12"
      md="4"
      class="d-flex"
    >
      <b-card
        :title="role.name[0].toUpperCase() + role.name.slice(1)"
        :sub-title="`Total ${role.totalUser} users`"
        class="flex-fill"
      >
        <b-card-body>
          <b-avatar-group size="32px">
            <b-avatar
              v-for="u of role.Users"
              :key="u.id"
              v-b-tooltip.hover
              class="pull-up"
              :title="u.fullName"
              :text="avatarText(u.fullName)"
              variant="primary"
            />
          </b-avatar-group>
        </b-card-body>
        <b-card-footer>
          <b-nav fill>
            <role-permissions
              v-if="$can('write', 'Permessi')"
              :indexref="''+index"
              :role="role"
              @saveRole="saveRole($event)"
            />
            <!-- <b-nav-item>
              List User
            </b-nav-item> -->
            <b-nav-item><b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="copyRole(role)"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>
            </b-nav-item>
            <b-nav-item
              v-if="role.Users.length==0"
            ><b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="deleteRole(role.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
            </b-nav-item>

          </b-nav>
        </b-card-footer>
      </b-card>

    </b-col>
    <b-col
      v-if="$can('write', 'Permessi')"
      cols="12"
      md="4"
    >
      <b-card
        title="New Role"
        sub-title="Add role, if it does not exist"
      >
        <b-card-footer>
          <b-nav fill>
            <role-permissions
              v-if="$can('write', 'Permessi')"
              indexref="new"
              :role="emptyRole"
              @saveRole="saveRole($event)"
            />
          </b-nav>
        </b-card-footer>
      </b-card>

    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BNav, BNavItem, BButton, BAvatarGroup, BAvatar, VBTooltip, VBModal,
} from 'bootstrap-vue'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
import userStoreModule from '../user/userStoreModule'
import RolePermissions from './RolePermissions.vue'

export default {
  components: {
    BRow, BCol, BCard, BNav, BNavItem, BButton, BAvatarGroup, BAvatar, RolePermissions,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  setup() {
    const roles = ref([])
    const emptyRole = ref({})
    const userData = getUserData()
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const fetchRoles = () => {
      store.dispatch('app-user/fetchRoles', { })
        .then(res => {
          roles.value = res.data.roles
          emptyRole.value = res.data.emptyRole
        // roleOptions.value = roles.value.roles.map(role => ({ label: role.name[0].toUpperCase() + role.name.slice(1), value: role.name }))
        })
        .catch(error => {
          if (error.response.status === 404) {
            roles.value = undefined
            emptyRole.value = undefined
          }
        })
    }
    fetchRoles()
    return {
      roles,
      emptyRole,
      avatarText,
      userData,
      fetchRoles,
    }
  },
  methods: {
    saveRole(event) {
      store.dispatch('app-user/addUpdateRolePermission', {
        userData: this.userData,
        role: event,
      })
        .then(res => {
          this.$swal({
            icon: 'success',
            title: 'Added!',
            text: 'Role has been added.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(resp => {
            this.fetchRoles()
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding role',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error,
            },
          })
        })
    },
    copyRole(role) {
      this.emptyRole = JSON.parse(JSON.stringify(role))
      this.emptyRole.id = -1
      this.emptyRole.name = ''
      this.$bvModal.show('role-new')
    },
    deleteRole(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-user/deleteRole', { id })
            .then(response => {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Role has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(res => {
                this.fetchRoles()
              })
              // this.$router.go()
            })
            .catch(error => {
              if (error.response.status === 404) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error deleting user',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
  // openModal(index) {
  //   this.$bvModal.show(`role-${index}`)
  //   console.log('open modal')
  // },

}
</script>

<style>

</style>
